import * as React from "react";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { Header, Grid, Card, List, Container, Feed, Segment, Comment, Icon, Tab, Embed } from "semantic-ui-react";
import { MarkdownRemarkConnection, ImageSharp } from "../graphql-types";
import BlogTitle from "../components/BlogTitle";
import TagsCard from "../components/TagsCard/TagsCard";
import BlogPagination from "../components/BlogPagination/BlogPagination";
import { get } from "lodash";
import {withLayout, LayoutProps} from "../components/Layout";
import { MarkdownRemark } from "../graphql-types";

const filterMenuStyle = {
    color: 'white',
    size: "huge"
  };
  
  const filterMenuActiveStyle = {
    color: '#70a5f9',
    size: "huge"
  };

const panes = [
    {menuItem: {key: 'Potential Investors and Donors', content:(<Segment vertical>
      <Header as="h3">
        <Icon name="magnify" />
        <Header.Content>
          Mathematics
        </Header.Content>
      </Header>
      </Segment>)}, render: () => <Tab.Pane as="borderless">
      <Segment vertical style={{fontSize:15}}>
          <p><Link to ="/walkthrough/">Introduction to Abstract Algebra <Icon name="cogs" /></Link></p>
          <p><Link to ="/walkthrough/">Introduction to Analysis <Icon name="history" /></Link> </p>
          <p><Link to ="/walkthrough/">Introduction to Topology <Icon name="cloud" /></Link> </p>
        </Segment>
      </Tab.Pane>},
    {menuItem: {key: 'Potential Investors and Donors', content: (<Segment vertical>
      <Header as="h3">
        <Icon name="code" />
        <Header.Content>
          Computer Science
        </Header.Content>
      </Header>
    </Segment>)}, render: () => <Tab.Pane as="borderless">
    <Segment vertical style={{fontSize:15}}>
          <p><Link to ="/walkthrough/">Programmatic Structure and Interpretation <Icon name="cogs" /></Link></p>
          <p><Link to ="/walkthrough/">Data-structures and Algorithms <Icon name="history" /></Link> </p>
          <p><Link to ="/walkthrough/">Software Development <Icon name="cloud" /></Link> </p>
        </Segment>
          </Tab.Pane>},
  ]

const WalkthroughPage = () => {
    const WalkthroughTitle = () => {
      return (
      <Segment vertical>
        <Header as="h2">
          <Icon name="graduation cap" />
          <Header.Content>
            Course Curriculum and Problem Walkthroughs
              <Header.Subheader>
              Get walkthrough exposure for common university subjects in Math and Computer Science.
              </Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      );
    }

    /*
  // TODO export posts in a proper component
  const Posts = (
    <Container>
      {posts.map(({ node }: {node: MarkdownRemark}) => {
        const { frontmatter, timeToRead, fields: { slug }, excerpt } = node;
        const avatar = frontmatter.author.avatar.children[0] as ImageSharp;
        const cover = get(frontmatter, "image.children.0.fixed", {});

        const extra = (
          <Comment.Group>
            <Comment>
              <Comment.Avatar
                src={avatar.fixed.src}
                srcSet={avatar.fixed.srcSet}
              />
              <Comment.Content>
                <Comment.Author style={{ fontWeight: 400 }}>
                  {frontmatter.author.id}
                </Comment.Author>
                <Comment.Metadata style={{ margin: 0 }}>
                  {frontmatter.updatedDate} - {timeToRead} min read
              </Comment.Metadata>
              </Comment.Content>
            </Comment>
          </Comment.Group>
        );

        const description = (
          <Card.Description>
            {excerpt}
            <br />
            <Link to={slug}>Read more…</Link>
          </Card.Description>
        );

        return (
          <Card key={slug}
            fluid
            image={cover}
            header={frontmatter.title}
            extra={extra}
            description={description}
            as={Link}
            to={slug}
          />
        );
      })}
    </Container>
  );

  */
  return (
    <Container>
      {/* Title */}
      <WalkthroughTitle />

      {/* Content Selector */}
      <Container>
        <Segment>
            <p>
            For any interested in projects based off this material we have a thread for chat at <a href="https://discord.gg/pyrFYVx" target="blank" rel="noopener">discord #skymake</a>.
            </p>
            <p>
            If you notice any cracks in this website, we are grateful to receive notices
            <a href="https://github.com/skymake/website/issues" target="blank" rel="noopener"> on this repository</a>.
            </p>
            <p>
            There's currently a section for mathematics and computer science, with intended additions in data science and artificial intelligence.
            </p>
        </Segment>
        <Tab menu={{secondary:true, pointing:true}} panes={panes}/>
      </Container>

      {/* Content */}
      <Embed icon='right circle arrow'
        placeholder = 'hello'
        defaultActive = {true}
        iframe={{
        allowFullScreen: true,
        style: {
            padding: 2,
        },
        }}
        url={"https://www.youtube.com/embed/B-YuAC52Q9c"} />
    </Container>
  );

};

export default withLayout(WalkthroughPage);

/*

CONTENT
<Segment vertical>
        <Grid padded style={{ justifyContent: "space-around" }}>
          <div style={{ maxWidth: 600 }}>
            { Posts }
            <Segment vertical textAlign="center">
              <BlogPagination Link={Link} pathname={pathname} pageCount={pageCount} />
            </Segment>
          </div>
          <div>
            <TagsCard Link={Link} tags={tags} tag={props.pageContext.tag} />
          </div>
        </Grid>
      </Segment>
*/